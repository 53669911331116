import PropTypes from 'prop-types';

const dataTypes = {
  images: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          relativePath: PropTypes.string.isRequired,
        }),
      }).isRequired,
    ).isRequired,
  }).isRequired,
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      author: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default dataTypes;