import PropTypes from 'prop-types';

import dataTypes from './dataTypes';

const imageTypes = {
  alt: PropTypes.string.isRequired,
  data: PropTypes.shape({
    images: dataTypes.images,
  }),
  url: PropTypes.string.isRequired,
};

export default imageTypes;