/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import classNames from 'classnames';

import imageTypes from 'types/imageTypes';

export class Image extends React.PureComponent {
  static propTypes = imageTypes;

  render() {
    const {
      alt, data, url, className, ...props
    } = this.props;
    const image = data.images.edges.find((n) => n.node.relativePath.includes(url));
    if (!image) {
      return null;
    }

    return (
      <Img
        className={classNames('Image', className)}
        alt={alt}
        {...props}
        image={image.node.childImageSharp.gatsbyImageData}
      />
    );
  }
}

const ImageStaticQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {ext: {nin: [".svg", "", ".js"]}}) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `}
    render={(data) => <Image data={data} {...props} />}
  />
);

export default ImageStaticQuery;
